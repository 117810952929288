<!--
  Only renders the children if the user has at least one of the passed roles assigned.
  Use the #else slot to render something if the user does not have the required roles.
-->
<template>
  <slot
    v-if="
      allowedRoles.length > 0 &&
      [...allowedRoles, AdminToolRoles.ReadWriteAll].some((role) =>
        authStore.hasRole(role),
      )
    "
  />
  <slot v-else name="else" />
</template>

<script lang="ts" setup>
import { AdminToolRoles } from "~/utils/rolesHelper";

defineProps<{
  /**
   * The roles that are allowed to see the content.
   */
  allowedRoles: AdminToolRoles[];
}>();

const authStore = useAdminAuthStore();
</script>
